const categories = [
	{
		value: 'beauty',
		label: 'Beauty',
		current: false,
	},
	{
		value: 'home-applicances',
		label: 'Home Applicances',
		current: false,
	},
	{
		value: 'pets',
		label: 'Pets Products',
		current: false,
	},
	{
		value: 'electronics',
		label: 'Electronices',
		current: false,
	},
	{
		value: 'garden',
		label: 'Garden Furniture',
		current: false,
	},
	{
		value: 'kitchen',
		label: 'Kitchen',
		current: false,
	},
	{
		value: 'fashion',
		label: 'Fashion',
		current: false,
	},
	{
		value: 'baby',
		label: 'Baby Products',
		current: false,
	},
	{
		value: 'sport',
		label: 'Sports',
		current: false,
	},
	{
		value: 'general',
		label: 'General',
		current: false,
	},
	{
		value: 'trend',
		label: 'Trend',
		current: false,
	},
]
export default categories
