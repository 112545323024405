export const influencersCategories = [
	{
		value: 'beauty',
		label: 'Beauty',
	},

	{
		value: 'travel',
		label: 'Travel',
	},
	{
		value: 'lifestyle',
		label: 'Lifestyle',
	},
	{
		value: 'celebrities',
		label: 'Celebrities and Entertainment',
	},
	{
		value: 'sports',
		label: 'Sports',
	},
	{
		value: 'animals',
		label: 'Animals',
	},
	{
		value: 'gaming',
		label: 'Gaming',
	},
	{
		value: 'kids',
		label: 'Kids',
	},
	{
		value: 'education',
		label: 'Education',
	},
	{
		value: 'memes',
		label: 'Meme pages',
	},
]
